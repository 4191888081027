/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that is used everywhere
 */

import { Box } from '@mui/material';
import { Dialog } from 'app/components/dialog';
import { ErrorBoundary } from 'ErrorBoundary';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes
} from 'react-router-dom';

import { RegisterLoginLayout } from './components/layouts';
import { closeModal } from './context/addModal/addModalSlice';
import { apiSlice } from './context/api';
import { useLogoutMutation } from './context/api/account';
import { login, logout, setUser } from './context/user/userSlice';
import { AddProperty } from './features/AddProperty';
import { MenuBar } from './features/Menu';
import { useAppDispatch, useAppSelector } from './hooks';
import * as config from './infrastructure/config';
import { useEnvironment } from './infrastructure/environment';
import { debug } from './infrastructure/log';
import { Home, Login, NotFound, Register } from './screens';
import { CreateClientModal } from './screens/client';
import { EditContractTemplate } from './screens/contractTemplate';
import Cpi from './screens/cpi';
import CpiPending from './screens/cpi/components/CpiPending';
import CpiPostponed from './screens/cpi/components/CpiPostponed';
import CpiRegulated from './screens/cpi/components/CpiRegulated';
import { ForgotPassword } from './screens/forgot-password';
import GjengsLeie from './screens/gjengsleie';
import GjengsleiePending from './screens/gjengsleie/components/GjengsleiePending';
import GjengsleieRegulated from './screens/gjengsleie/components/GjengsleieRegulated';
import { Fiken } from './screens/integrations/fiken';
import { Properties } from './screens/properties';
import { Property } from './screens/properties/property';
import { CreatePropertyModal } from './screens/property';
import { AddContractModal } from './screens/tenancy/AddContract';
import { CreateTenantModal } from './screens/tenancy/tenant';
import { BackgroundColor, Container } from './styles';
import { white } from './theme/colors';

interface Props {
  user: boolean;
  redirectPath?: string;
}

async function authQuery() {
  const settings = {
    method: 'POST',
    credentials: 'include' as RequestCredentials,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}Account/IsAuthenticated`,
    settings
  );
  const parsed = await result.json();
  return parsed;
}

const ProtectedRoute = ({ user, redirectPath = '/login' }: Props) => {
  const menuWidth = 280;
  const open = useAppSelector((state) => state.menu.open);
  const modal = useAppSelector((state) => state.modal.modal);

  const dispatch = useAppDispatch();

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <BackgroundColor>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <MenuBar anchor={'left'} />
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <MenuBar anchor={'top'} />
      </Box>
      <Container
        sx={{
          backgroundColor: white[20],
          width: {
            xs: '95%',
            md: open ? `calc(95% - ${menuWidth}px)` : `calc(95% - 50px)`
          },
          mt: { xs: 15, md: 5 },
          ml: {
            xs: 1,
            md: open ? `calc(${menuWidth}px + 2.5%)` : `calc(50px + 2.5%)`
          }
        }}
      >
        <ErrorBoundary>
          <Outlet />
          <Box>
            <Dialog isOpen={modal === 'property'}>
              <AddProperty
                callback={() => dispatch(closeModal)}
                redirect={process.env.REACT_APP_API_URL + 'Property/Properties'}
              />
            </Dialog>
          </Box>
        </ErrorBoundary>
      </Container>
    </BackgroundColor>
  );
};

const LogoutRoute = () => {
  const [logoutCall] = useLogoutMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function LogOut() {
      await logoutCall();
      dispatch(logout());
      dispatch(apiSlice.util.resetApiState());
    }
    LogOut();
  }, []);

  return <Navigate to="/login" replace />;
};

export const App = () => {
  const { t, i18n } = useTranslation();
  const { environment } = useEnvironment();
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const [authLoading, setAuthLoading] = useState(true);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    async function fetchAuth() {
      await authQuery()
        .then((response) => {
          if (response.IsAuthenticated) {
            dispatch(setUser(response));
            dispatch(login());
          }
          setAuthLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setAuthLoading(false);
        });
    }
    fetchAuth();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(config.appConfig.defaultLanguage);
  }, [i18n]);

  debug('Config', config);

  // blank screen until auth and environment is resolved
  if (environment === undefined || authLoading) return <></>;

  return (
    <Router>
      <Helmet titleTemplate={`%s - ${t('title')}`} defaultTitle={t('title')} />
      <Routes>
        <Route element={<ProtectedRoute user={!!user.isAuthenticated} />}>
          <Route path="/" element={<Home />} />
          <Route path="/Home/Dashboard" element={<Home />} />
          <Route path="logout" element={<LogoutRoute />} />
          <Route path="/integrations">
            <Route path="fiken" element={<Fiken />} />
          </Route>
          <Route path="/properties" element={<Properties />} />
          <Route path="/properties/:id/:view" element={<Property />} />

          <Route
            path="/ContractTemplate/Edit"
            element={<EditContractTemplate />}
          />
          <Route
            path="/ContractTemplate/Create"
            element={<EditContractTemplate />}
          />
          <Route path="/User/EditTenant" element={<AddContractModal />} />
          <Route
            path="/User/TenantContactList"
            element={<CreateTenantModal />}
          />
          <Route
            path="/Property/CreateProperty"
            element={<CreatePropertyModal />}
          />
          <Route path="/Client/CreateClient" element={<CreateClientModal />} />
          <Route path="/Cpi" element={<Cpi />}>
            <Route path="Pending" element={<CpiPending />} />
            <Route path="Postponed" element={<CpiPostponed />} />
            <Route path="Regulated" element={<CpiRegulated />} />
          </Route>
          <Route path="/CustomRegulation" element={<GjengsLeie />}>
            <Route path="Pending" element={<GjengsleiePending />} />
            <Route path="Regulated" element={<GjengsleieRegulated />} />
          </Route>
        </Route>
        <Route element={<RegisterLoginLayout />}>
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="ForgotPassword" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
